/**
 * @generated SignedSource<<6c90615a4e72f52944e62a07d9e5e9b7>>
 * @relayHash a4690bc09f056025b0a625f18a51dc1d
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-buyer-trade/504.0.0-2025-04-03T17:17:03.867Z/ProfileRootQuery

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProfileRootQuery$variables = {
  firmUrlLabel: string;
  isEditMode: boolean;
  shouldRenderOverview: boolean;
  shouldRenderProjects: boolean;
};
export type ProfileRootQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"DesignProfileWrapper_viewer">;
  };
};
export type ProfileRootQuery = {
  response: ProfileRootQuery$data;
  variables: ProfileRootQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "firmUrlLabel"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isEditMode"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "shouldRenderOverview"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "shouldRenderProjects"
},
v4 = {
  "kind": "Variable",
  "name": "urlLabel",
  "variableName": "firmUrlLabel"
},
v5 = [
  (v4/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "urlLabel",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profileStatus",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "about",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v13 = [
  (v7/*: any*/),
  (v6/*: any*/),
  (v12/*: any*/),
  (v9/*: any*/)
],
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "path",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v16 = {
  "kind": "Literal",
  "name": "ascDesc",
  "value": "DESC"
},
v17 = {
  "kind": "Literal",
  "name": "orderBy",
  "value": "MODIFIED_DATE"
},
v18 = [
  (v16/*: any*/),
  (v17/*: any*/),
  {
    "kind": "Literal",
    "name": "status",
    "value": [
      "ACTIVE",
      "PENDING"
    ]
  },
  {
    "kind": "Literal",
    "name": "visibility",
    "value": [
      "Y",
      "N"
    ]
  }
],
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "visibility",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "addressDisplayString",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rootId",
  "storageKey": null
},
v22 = {
  "alias": "editModeDesignProjects",
  "args": (v18/*: any*/),
  "concreteType": "designProject",
  "kind": "LinkedField",
  "name": "designProjects",
  "plural": true,
  "selections": [
    (v12/*: any*/),
    (v19/*: any*/),
    (v7/*: any*/),
    (v6/*: any*/),
    (v20/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "internalEditUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "editUrl",
      "storageKey": null
    },
    (v21/*: any*/),
    (v15/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "TradePhoto",
      "kind": "LinkedField",
      "name": "tradeCoverPhoto",
      "plural": false,
      "selections": [
        (v14/*: any*/),
        (v9/*: any*/)
      ],
      "storageKey": null
    },
    (v9/*: any*/)
  ],
  "storageKey": "designProjects(ascDesc:\"DESC\",orderBy:\"MODIFIED_DATE\",status:[\"ACTIVE\",\"PENDING\"],visibility:[\"Y\",\"N\"])"
},
v23 = [
  (v16/*: any*/),
  (v17/*: any*/)
],
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "coverPhoto",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProfileRootQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DesignProfileWrapper_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "ProfileRootQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "TradeFirmType",
            "kind": "LinkedField",
            "name": "tradeFirms",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "TradeFirmPublicProfileType",
                "kind": "LinkedField",
                "name": "publicProfile",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayLocation",
                    "storageKey": null
                  },
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "designProjectsCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PortfolioV1",
                    "kind": "LinkedField",
                    "name": "portfolios",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "itemCount",
                        "storageKey": null
                      },
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "profilePhoto",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "splashPhotoId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "profilePhotoId",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v10/*: any*/),
              (v9/*: any*/),
              {
                "condition": "isEditMode",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TradeFirmPublicProfileType",
                    "kind": "LinkedField",
                    "name": "publicProfile",
                    "plural": false,
                    "selections": [
                      (v11/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "splashPhoto",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DesignProjectCover",
                        "kind": "LinkedField",
                        "name": "designProjectsCover",
                        "plural": true,
                        "selections": [
                          (v12/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Address",
                        "kind": "LinkedField",
                        "name": "address",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "country",
                            "storageKey": null
                          },
                          (v9/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PortfolioV1",
                        "kind": "LinkedField",
                        "name": "portfolios",
                        "plural": true,
                        "selections": [
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ]
              }
            ],
            "storageKey": null
          },
          {
            "alias": "tradePortfolioInstagram",
            "args": [
              {
                "kind": "Literal",
                "name": "feature",
                "value": "tradePortfolioInstagram"
              }
            ],
            "kind": "ScalarField",
            "name": "featureFlag",
            "storageKey": "featureFlag(feature:\"tradePortfolioInstagram\")"
          },
          (v9/*: any*/),
          {
            "condition": "shouldRenderOverview",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "TradeFirmType",
                "kind": "LinkedField",
                "name": "tradeFirms",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TradeFirmPublicProfileType",
                    "kind": "LinkedField",
                    "name": "publicProfile",
                    "plural": false,
                    "selections": [
                      (v11/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AwardType",
                        "kind": "LinkedField",
                        "name": "awards",
                        "plural": true,
                        "selections": (v13/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DesignFirmArticleType",
                        "kind": "LinkedField",
                        "name": "articles",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Photo",
                            "kind": "LinkedField",
                            "name": "photo",
                            "plural": false,
                            "selections": [
                              (v7/*: any*/),
                              (v14/*: any*/),
                              (v9/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "WebLink",
                            "kind": "LinkedField",
                            "name": "webLink",
                            "plural": false,
                            "selections": [
                              (v15/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "WebLinkType",
                        "kind": "LinkedField",
                        "name": "webLinks",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "facebook",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "instagram",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "pinterest",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "website",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "condition": "isEditMode",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          {
                            "alias": "liveDesignProjectsCount",
                            "args": null,
                            "concreteType": "designProject",
                            "kind": "LinkedField",
                            "name": "designProjects",
                            "plural": true,
                            "selections": [
                              (v7/*: any*/),
                              (v9/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v22/*: any*/),
                          {
                            "alias": null,
                            "args": (v18/*: any*/),
                            "concreteType": "designProject",
                            "kind": "LinkedField",
                            "name": "designProjects",
                            "plural": true,
                            "selections": [
                              (v15/*: any*/),
                              (v9/*: any*/)
                            ],
                            "storageKey": "designProjects(ascDesc:\"DESC\",orderBy:\"MODIFIED_DATE\",status:[\"ACTIVE\",\"PENDING\"],visibility:[\"Y\",\"N\"])"
                          }
                        ]
                      },
                      {
                        "condition": "isEditMode",
                        "kind": "Condition",
                        "passingValue": false,
                        "selections": [
                          {
                            "alias": "liveDesignProjects",
                            "args": (v23/*: any*/),
                            "concreteType": "DesignProjectCover",
                            "kind": "LinkedField",
                            "name": "designProjectsCover",
                            "plural": true,
                            "selections": [
                              (v7/*: any*/),
                              (v20/*: any*/),
                              (v6/*: any*/),
                              (v21/*: any*/),
                              (v12/*: any*/),
                              (v24/*: any*/),
                              (v15/*: any*/),
                              (v19/*: any*/)
                            ],
                            "storageKey": "designProjectsCover(ascDesc:\"DESC\",orderBy:\"MODIFIED_DATE\")"
                          }
                        ]
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "InstagramFeedType",
                    "kind": "LinkedField",
                    "name": "instagram",
                    "plural": false,
                    "selections": [
                      {
                        "condition": "isEditMode",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "username",
                            "storageKey": null
                          }
                        ]
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "InstagramFeedItemType",
                        "kind": "LinkedField",
                        "name": "feed",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "imageUrl",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "link",
                            "storageKey": null
                          },
                          (v9/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "kind": "ClientExtension",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__id",
                            "storageKey": null
                          }
                        ]
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ]
          },
          {
            "condition": "isEditMode",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AwardType",
                "kind": "LinkedField",
                "name": "designAwardsQuery",
                "plural": true,
                "selections": (v13/*: any*/),
                "storageKey": null
              }
            ]
          },
          {
            "condition": "shouldRenderProjects",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": "designFirm",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "pageSize",
                    "value": 1
                  },
                  (v4/*: any*/)
                ],
                "concreteType": "TradeFirmType",
                "kind": "LinkedField",
                "name": "tradeFirms",
                "plural": true,
                "selections": [
                  (v7/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TradeFirmPublicProfileType",
                    "kind": "LinkedField",
                    "name": "publicProfile",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      {
                        "condition": "isEditMode",
                        "kind": "Condition",
                        "passingValue": false,
                        "selections": [
                          {
                            "alias": "liveDesignProjects",
                            "args": (v23/*: any*/),
                            "concreteType": "DesignProjectCover",
                            "kind": "LinkedField",
                            "name": "designProjectsCover",
                            "plural": true,
                            "selections": [
                              (v7/*: any*/),
                              (v20/*: any*/),
                              (v6/*: any*/),
                              (v21/*: any*/),
                              (v12/*: any*/),
                              (v24/*: any*/),
                              (v15/*: any*/)
                            ],
                            "storageKey": "designProjectsCover(ascDesc:\"DESC\",orderBy:\"MODIFIED_DATE\")"
                          }
                        ]
                      },
                      {
                        "condition": "isEditMode",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          (v22/*: any*/)
                        ]
                      }
                    ],
                    "storageKey": null
                  },
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "app-buyer-trade/504.0.0-2025-04-03T17:17:03.867Z/ProfileRootQuery",
    "metadata": {},
    "name": "ProfileRootQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "0ef0798500544a65def2b10e934aadc8";

export default node;
