/**
 * @generated SignedSource<<1403d85183a2d9c9d36b2a9cf93a6b18>>
 * @relayHash 5aa5ea6849c3a02c037967b5d4c3a909
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-buyer-trade/504.0.0-2025-04-03T17:17:03.867Z/profileMediaMutation

import { ConcreteRequest } from 'relay-runtime';
export type FirmDesignProfileMediaMutationInput = {
  clientMutationId?: string | null;
  profilePhotoId?: string | null;
  serviceId: string;
  splashPhotoId?: string | null;
};
export type profileMediaMutation$variables = {
  input: FirmDesignProfileMediaMutationInput;
};
export type profileMediaMutation$data = {
  readonly firmDesignProfileMediaMutation: {
    readonly tradeFirm: {
      readonly publicProfile: {
        readonly profilePhoto: string | null;
        readonly profilePhotoId: string | null;
        readonly splashPhoto: string | null;
        readonly splashPhotoId: string | null;
      } | null;
    } | null;
  } | null;
};
export type profileMediaMutation = {
  response: profileMediaMutation$data;
  variables: profileMediaMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "TradeFirmPublicProfileType",
  "kind": "LinkedField",
  "name": "publicProfile",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "profilePhoto",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "profilePhotoId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "splashPhoto",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "splashPhotoId",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "profileMediaMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "FirmDesignProfileMediaMutationPayload",
        "kind": "LinkedField",
        "name": "firmDesignProfileMediaMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TradeFirmType",
            "kind": "LinkedField",
            "name": "tradeFirm",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "profileMediaMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "FirmDesignProfileMediaMutationPayload",
        "kind": "LinkedField",
        "name": "firmDesignProfileMediaMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TradeFirmType",
            "kind": "LinkedField",
            "name": "tradeFirm",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "app-buyer-trade/504.0.0-2025-04-03T17:17:03.867Z/profileMediaMutation",
    "metadata": {},
    "name": "profileMediaMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "87a3b2d8897a19074644a0a651774bb0";

export default node;
