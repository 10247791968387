/**
 * @generated SignedSource<<9b0620637877e1452279b49cbadfb503>>
 * @relayHash e644cfdb7754aa958cdfe9341fe1e581
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-buyer-trade/504.0.0-2025-04-03T17:17:03.867Z/followPageMutation

import { ConcreteRequest } from 'relay-runtime';
export type FavoriteAction = "ADD" | "REMOVE" | "%future added value";
export type FavoritePageInput = {
  action: FavoriteAction;
  clientMutationId?: string | null;
  favoriteId?: string | null;
  name?: string | null;
  page: string;
  query?: string | null;
  type: string;
  userId: string;
};
export type followPageMutation$variables = {
  input: FavoritePageInput;
};
export type followPageMutation$data = {
  readonly favoritePage: {
    readonly user: {
      readonly favorites: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly anchor: {
              readonly sourceId: string | null;
            } | null;
            readonly serviceId: string | null;
          } | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};
export type followPageMutation = {
  response: followPageMutation$data;
  variables: followPageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = [
  {
    "kind": "Literal",
    "name": "types",
    "value": [
      "DESIGNER"
    ]
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Anchor",
  "kind": "LinkedField",
  "name": "anchor",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sourceId",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "followPageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "FavoritePagePayload",
        "kind": "LinkedField",
        "name": "favoritePage",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "favoritesConnection",
                "kind": "LinkedField",
                "name": "favorites",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "favoritesEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Favorite",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "favorites(types:[\"DESIGNER\"])"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "followPageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "FavoritePagePayload",
        "kind": "LinkedField",
        "name": "favoritePage",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "favoritesConnection",
                "kind": "LinkedField",
                "name": "favorites",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "favoritesEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Favorite",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "favorites(types:[\"DESIGNER\"])"
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "app-buyer-trade/504.0.0-2025-04-03T17:17:03.867Z/followPageMutation",
    "metadata": {},
    "name": "followPageMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "f7a969b846799d9136e6f11749d6c98d";

export default node;
