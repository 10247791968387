/**
 * @generated SignedSource<<d59e026ac314d6573cf05f22799e6076>>
 * @relayHash 598e968761d980a65c8791c5e98688ac
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-buyer-trade/504.0.0-2025-04-03T17:17:03.867Z/OverviewInstagramRefetchQuery

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OverviewInstagramRefetchQuery$variables = {
  firmUrlLabel: string;
  isEditMode: boolean;
};
export type OverviewInstagramRefetchQuery$data = {
  readonly viewer: {
    readonly tradeFirms: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"OverviewInstagram_tradeFirm">;
    } | null> | null;
  };
};
export type OverviewInstagramRefetchQuery = {
  response: OverviewInstagramRefetchQuery$data;
  variables: OverviewInstagramRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "firmUrlLabel"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isEditMode"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "urlLabel",
    "variableName": "firmUrlLabel"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OverviewInstagramRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "TradeFirmType",
            "kind": "LinkedField",
            "name": "tradeFirms",
            "plural": true,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "OverviewInstagram_tradeFirm"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OverviewInstagramRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "TradeFirmType",
            "kind": "LinkedField",
            "name": "tradeFirms",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "serviceId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "InstagramFeedType",
                "kind": "LinkedField",
                "name": "instagram",
                "plural": false,
                "selections": [
                  {
                    "condition": "isEditMode",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "username",
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "InstagramFeedItemType",
                    "kind": "LinkedField",
                    "name": "feed",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "imageUrl",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "link",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "app-buyer-trade/504.0.0-2025-04-03T17:17:03.867Z/OverviewInstagramRefetchQuery",
    "metadata": {},
    "name": "OverviewInstagramRefetchQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "3092b55d77cc31df57f6b021f66f0bcb";

export default node;
