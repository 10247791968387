/**
 * @generated SignedSource<<94e6f241f075204350d5019c262824ca>>
 * @relayHash 81c384e9eb44728ff3b431797b5b45f5
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-buyer-trade/504.0.0-2025-04-03T17:17:03.867Z/FavoritesWrapperRefetchQuery

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FavoritesWrapperRefetchQuery$variables = {
  favoritesPage: number;
  firmUrlLabel: string;
  isEditMode: boolean;
  isTrade: boolean;
};
export type FavoritesWrapperRefetchQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"FavoritesWrapper_viewer">;
  };
};
export type FavoritesWrapperRefetchQuery = {
  response: FavoritesWrapperRefetchQuery$data;
  variables: FavoritesWrapperRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "favoritesPage"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "firmUrlLabel"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isEditMode"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isTrade"
},
v4 = [
  {
    "kind": "Variable",
    "name": "urlLabel",
    "variableName": "firmUrlLabel"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "LinkData",
  "kind": "LinkedField",
  "name": "linkData",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "path",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isLinkable",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v12 = [
  {
    "kind": "Literal",
    "name": "formatType",
    "value": "MOBILE"
  }
],
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v14 = [
  (v11/*: any*/)
],
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "TradeFirmPublicProfileType",
  "kind": "LinkedField",
  "name": "publicProfile",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PortfolioV2",
      "kind": "LinkedField",
      "name": "portfoliosInflated",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 60
            },
            {
              "kind": "Variable",
              "name": "page",
              "variableName": "favoritesPage"
            }
          ],
          "concreteType": "PortfolioItemsConnection",
          "kind": "LinkedField",
          "name": "items",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "numberOfPages",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalResults",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PortfolioItemsEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PortfolioItemV2",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Item",
                      "kind": "LinkedField",
                      "name": "item",
                      "plural": false,
                      "selections": [
                        (v5/*: any*/),
                        (v6/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "pdpMetaItems",
                          "kind": "LinkedField",
                          "name": "pdpMeta",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "topQuery",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "localizedPdpUrl",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ItemClassification",
                          "kind": "LinkedField",
                          "name": "classification",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "creationDate",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "ItemCategory",
                              "kind": "LinkedField",
                              "name": "categories",
                              "plural": true,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "Category",
                                  "kind": "LinkedField",
                                  "name": "category",
                                  "plural": false,
                                  "selections": [
                                    (v7/*: any*/),
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "browseUrl",
                                      "storageKey": null
                                    },
                                    (v8/*: any*/)
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ItemCreator",
                          "kind": "LinkedField",
                          "name": "creators",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "attribution",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Creator",
                              "kind": "LinkedField",
                              "name": "creator",
                              "plural": false,
                              "selections": [
                                (v9/*: any*/),
                                (v8/*: any*/)
                              ],
                              "storageKey": null
                            },
                            (v8/*: any*/)
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "isArt",
                          "storageKey": null
                        },
                        (v10/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "vertical",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ShipmentQuote",
                          "kind": "LinkedField",
                          "name": "shipmentQuotes",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "isComplimentary",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "isCalculated",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "FinancialAmount",
                              "kind": "LinkedField",
                              "name": "totalAmount",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "ConvertedAmounts",
                                  "kind": "LinkedField",
                                  "name": "convertedAmounts",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "USD",
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "ShipmentServiceMethod",
                              "kind": "LinkedField",
                              "name": "serviceMethod",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "Region",
                                  "kind": "LinkedField",
                                  "name": "region",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "regionName",
                                      "storageKey": null
                                    },
                                    (v8/*: any*/)
                                  ],
                                  "storageKey": null
                                },
                                (v8/*: any*/)
                              ],
                              "storageKey": null
                            },
                            (v8/*: any*/)
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "isStorefrontOnly",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "isMultiSku",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "isRingItem",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "VariableAttribute",
                          "kind": "LinkedField",
                          "name": "variableAttributes",
                          "plural": true,
                          "selections": [
                            (v5/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "VariableAttributeSortType",
                              "kind": "LinkedField",
                              "name": "variableAttributeData",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "SwatchSort",
                                  "kind": "LinkedField",
                                  "name": "swatchSort",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "Swatch",
                                      "kind": "LinkedField",
                                      "name": "swatch",
                                      "plural": false,
                                      "selections": [
                                        (v5/*: any*/),
                                        (v9/*: any*/),
                                        {
                                          "alias": null,
                                          "args": null,
                                          "kind": "ScalarField",
                                          "name": "imageUrl",
                                          "storageKey": null
                                        },
                                        (v8/*: any*/)
                                      ],
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            },
                            (v8/*: any*/)
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": [
                            {
                              "kind": "Variable",
                              "name": "isTrade",
                              "variableName": "isTrade"
                            },
                            {
                              "kind": "Literal",
                              "name": "page",
                              "value": "searchAndBrowse"
                            }
                          ],
                          "concreteType": "ItemDisplayPriceType",
                          "kind": "LinkedField",
                          "name": "displayPrice",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "ConvertedAmountList",
                              "kind": "LinkedField",
                              "name": "convertedAmountList",
                              "plural": true,
                              "selections": [
                                (v11/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "currency",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "quantityDisplay",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "percentageOff",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "textType",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "amountType",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "showPriceLabel",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "showPriceVariability",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "QuickViewDisplayType",
                          "kind": "LinkedField",
                          "name": "quickViewDisplay",
                          "plural": false,
                          "selections": [
                            (v6/*: any*/),
                            {
                              "alias": "mobileTitle",
                              "args": (v12/*: any*/),
                              "kind": "ScalarField",
                              "name": "title",
                              "storageKey": "title(formatType:\"MOBILE\")"
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "QuickViewParagraph",
                              "kind": "LinkedField",
                              "name": "paragraphs",
                              "plural": true,
                              "selections": [
                                (v7/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "text",
                                  "storageKey": null
                                },
                                {
                                  "alias": "mobileText",
                                  "args": (v12/*: any*/),
                                  "kind": "ScalarField",
                                  "name": "text",
                                  "storageKey": "text(formatType:\"MOBILE\")"
                                }
                              ],
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "QuickViewCreator",
                              "kind": "LinkedField",
                              "name": "creators",
                              "plural": true,
                              "selections": [
                                (v9/*: any*/),
                                (v10/*: any*/)
                              ],
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "QuickViewCategorySegments",
                              "kind": "LinkedField",
                              "name": "paragraphAttributeNames",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "period",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "origin",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "style",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "periodPrefix",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ItemMeasurement",
                          "kind": "LinkedField",
                          "name": "measurement",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": [
                                {
                                  "kind": "Literal",
                                  "name": "addLabel",
                                  "value": true
                                },
                                {
                                  "kind": "Literal",
                                  "name": "fields",
                                  "value": [
                                    "height",
                                    "width",
                                    "depth",
                                    "diameter",
                                    "length"
                                  ]
                                },
                                {
                                  "kind": "Literal",
                                  "name": "separator",
                                  "value": " "
                                }
                              ],
                              "concreteType": "ItemDisplayMeasurementType",
                              "kind": "LinkedField",
                              "name": "display",
                              "plural": true,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "unit",
                                  "storageKey": null
                                },
                                (v13/*: any*/)
                              ],
                              "storageKey": "display(addLabel:true,fields:[\"height\",\"width\",\"depth\",\"diameter\",\"length\"],separator:\" \")"
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "ItemSize",
                              "kind": "LinkedField",
                              "name": "size",
                              "plural": false,
                              "selections": [
                                (v13/*: any*/)
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": "productPhotos",
                          "args": [
                            {
                              "kind": "Literal",
                              "name": "limit",
                              "value": 1
                            }
                          ],
                          "concreteType": "ItemPhoto",
                          "kind": "LinkedField",
                          "name": "photos",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "placeholder",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "smallPath",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "masterOrZoomPath",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "ItemPhotoVersion",
                              "kind": "LinkedField",
                              "name": "versions",
                              "plural": true,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "webPath",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            },
                            (v8/*: any*/)
                          ],
                          "storageKey": "photos(limit:1)"
                        },
                        {
                          "alias": null,
                          "args": [
                            {
                              "kind": "Literal",
                              "name": "size",
                              "value": "thumb"
                            }
                          ],
                          "kind": "ScalarField",
                          "name": "firstPhotoWebPath",
                          "storageKey": "firstPhotoWebPath(size:\"thumb\")"
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Seller",
                          "kind": "LinkedField",
                          "name": "seller",
                          "plural": false,
                          "selections": [
                            (v5/*: any*/),
                            (v8/*: any*/)
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "isContemporary",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "isCustomizable",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "isNewAndMadeToOrder",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "ecommerceTrackingParams",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ItemPricing",
                          "kind": "LinkedField",
                          "name": "pricing",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "FinancialAmount",
                              "kind": "LinkedField",
                              "name": "amount",
                              "plural": false,
                              "selections": (v14/*: any*/),
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "FinancialAmount",
                              "kind": "LinkedField",
                              "name": "netPrice",
                              "plural": false,
                              "selections": (v14/*: any*/),
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "FinancialAmount",
                              "kind": "LinkedField",
                              "name": "salePrice",
                              "plural": false,
                              "selections": (v14/*: any*/),
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        (v8/*: any*/)
                      ],
                      "storageKey": null
                    },
                    (v8/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        (v8/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "FavoritesWrapperRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "FavoritesWrapper_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "FavoritesWrapperRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "condition": "isEditMode",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              {
                "alias": "tradeFirmsDisplayMode",
                "args": (v4/*: any*/),
                "concreteType": "TradeFirmType",
                "kind": "LinkedField",
                "name": "tradeFirms",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  (v15/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ]
          },
          {
            "condition": "isEditMode",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": "tradeFirmsEditMode",
                "args": (v4/*: any*/),
                "concreteType": "TradeFirmType",
                "kind": "LinkedField",
                "name": "tradeFirms",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TradeFirmMemberType",
                    "kind": "LinkedField",
                    "name": "members",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "userId",
                        "storageKey": null
                      },
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v15/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ]
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "app-buyer-trade/504.0.0-2025-04-03T17:17:03.867Z/FavoritesWrapperRefetchQuery",
    "metadata": {},
    "name": "FavoritesWrapperRefetchQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "b994e1d7a75caaba68ee29d6e9a1358d";

export default node;
